.upcoming-events {
  padding: 16px;
}

.upcoming-events h2 {
  font-size: 22px;
  margin-bottom: 16px;
  text-align: left;
}

.events-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth; /* Desplazamiento suave */
  gap: 16px; /* Espaciado entre cards */
}

.event-card {
  flex: 0 0 auto; /* Evita que las cards se expandan */
  width: 150px; /* Ancho de cada card */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: white;
  padding: 8px;
  transition: transform 0.2s ease-in-out; /* Transición suave para hover */
}

.event-image {
  width: 100%;
  height: 100px; /* Establecer altura fija para que las imágenes sean uniformes */
  object-fit: cover; /* Asegurar que las imágenes no se deformen */
}

.event-name {
  font-size: 16px;
  margin: 8px 0;
}

.event-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.event-location {
  font-size: 12px;
  color: #999;
}

.event-card:hover {
  transform: translateY(-5px); /* Efecto de elevación al hacer hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Aumentar la sombra al pasar el cursor */
}

/* Adaptación a pantallas pequeñas */
@media (max-width: 768px) {
  .upcoming-events h2 {
    font-size: 18px; /* Reducir tamaño de la fuente en móviles */
  }

  .event-card {
    width: 120px; /* Reducir el tamaño de las tarjetas en pantallas más pequeñas */
  }

  .event-name {
    font-size: 14px;
  }

  .event-description {
    font-size: 12px;
  }

  .events-container {
    gap: 8px; /* Reducir el espaciado entre las tarjetas */
  }
}

.events-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Suaviza el desplazamiento en dispositivos iOS */
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Mejora la experiencia de scroll en dispositivos móviles */
  scrollbar-width: none; /* Ocultar la barra de scroll en Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Ocultar la barra de scroll en Chrome */
}
