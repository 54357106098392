.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Jakarta Sans';
  src: url('./assets/fonts/jakartasans_light.ttf') format('truetype'); /* Asegúrate de que la ruta sea correcta */
  font-weight: bold; /* Ajusta según el peso de la fuente */
  font-style: normal; /* Ajusta si es cursiva */
}

body {
  font-family: 'Jakarta Sans', sans-serif; /* Aplica la fuente a todo el cuerpo */
}

h1 {
  font-family: 'Jakarta Sans', sans-serif;
}

p {
  font-family: 'Jakarta Sans', sans-serif;
}

@media only screen and (max-width: 768px) {
  .header-image {
    width: 100%;
    height: auto;
  }

  .grid-container {
    grid-template-columns: 1fr 1fr; /* Dos columnas en lugar de tres para pantallas pequeñas */
  }

  .pharmacy-card {
    margin: 10px; /* Ajustar los márgenes para pantallas más pequeñas */
  }
}

@media only screen and (max-width: 480px) {
  .grid-container {
    grid-template-columns: 1fr; /* Una columna en pantallas muy pequeñas */
  }

  .header-image {
    height: 150px; /* Ajustar altura del encabezado */
  }

  .pharmacy-card {
    margin: 2px;
  }
}

.drawer {
  font-family: 'Jakarta Sans'; /* Aplica tu tipografía al Drawer */
}

.category-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.category-button {
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #eefdff;
}

.category-button:hover {
  background-color: #fffefe;
}
