.app-container {
  text-align: center;
}

.header {
  position: relative;
}

.header-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
}

.pharmacy-section {
  margin-top: 20px;
  padding: 20px;
}

.pharmacy-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas por defecto */
  gap: 16px; /* Espacio entre las tarjetas */
  padding: 8px; /* Espacio alrededor del contenedor */
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f7ff; /* Fondo claro */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra suave */
  padding: 16px;
  transition: transform 0.2s ease; /* Añade una ligera animación */
  text-align: center; /* Asegura que el texto esté centrado */
}

.grid-item:hover {
  transform: scale(1.05); /* Pequeña animación al pasar el cursor */
}

.grid-item img {
  width: 40px; /* Tamaño del ícono */
  height: 40px;
  margin-bottom: 8px; /* Espacio entre el ícono y el texto */
}

.grid-item p {
  font-size: 14px;
  color: #333; /* Color del texto */
  margin: 0; /* Eliminar márgenes predeterminados */
}
