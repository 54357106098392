.shimmer-card {
  width: 95%; /* Usa un porcentaje para que sea flexible */
  max-width: 200px; /* Máximo ancho para pantallas grandes */
  height: auto; /* Ajusta la altura automáticamente */
  aspect-ratio: 1 / 1; /* Mantiene la proporción cuadrada */
  background-color: #f5f7ff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  margin: auto; /* Centra la tarjeta dentro de su contenedor */
}

.shimmer-logo {
  width: 100%;
  height: 90%;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 8px 8px 0 0; /* Redondea la parte superior */
}

.shimmer-name {
  width: 60%;
  height: 16px;
  margin: 8px auto;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
      background-position: 200% 0;
  }
  100% {
      background-position: -200% 0;
  }
}
