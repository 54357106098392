.header-image-container {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 8px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.1), transparent);
}

.app-name {
  font-size: 20px;
  font-weight: bold;
  color: white;
  padding-left: 8px;
}

.social-media-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  color: white;
}

.author-name {
  font-size: 14px;
  margin-bottom: 4px;
}

.social-link {
  font-size: 12px;
  color: white;
  text-decoration: none;
}

.social-link:hover {
  text-decoration: underline;
}

.icon-button {
  z-index: 10; /* Asegura que el botón esté por encima de otros elementos */
}
