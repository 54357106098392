.search {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 32px); /* Para dejar un padding de 16px a cada lado */
    max-width: 600px; /* Puedes ajustar esto según tu diseño */
    margin: 0 auto; /* Centra el SearchBar */
    position: relative;
    margin-top: 16px;
    margin-bottom: 24px;
}

.searchInputs {
    display: flex;
    align-items: center;
    width: 100%; /* Asegúrate de que ocupe el 100% */
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 8px 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.searchInputs input {
    width: 100%; /* Ocupa todo el ancho disponible */
    border: none;
    outline: none;
    background: none;
    padding: 12px;
    font-size: 16px;
    color: #333;
}

.searchInputs input::placeholder {
    color: #999;
}

.searchInputs:hover {
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.15);
}

.searchIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #666;
}

.dataResult {
    position: absolute;
    top: 100%; /* Aparece justo debajo de la barra de búsqueda */
    left: 0;
    width: calc(100% - 32px); /* Resta el padding de ambos lados */
    max-height: 300px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    z-index: 10;
    padding: 10px;
}

.dataItem {
    padding: 12px 12px;
    cursor: pointer;
    border-bottom: 2px solid #f0f0f0;
    transition: background-color 0.3s;
}

.dataItem:last-child {
    border-bottom: none; /* Elimina la línea divisoria del último elemento */
}


.dataItem:hover {
    background-color: #f0f0f0;
}

.dataItem p {
    margin: 0; /* Elimina margen por defecto */
}

.marketName {
    margin: 0; /* Elimina margen por defecto */
    font-size: 22px; /* Tamaño de texto más grande para el nombre */
    font-weight: bold; /* Negrita para el nombre */
    color: #333; /* Color del texto del nombre */
    padding-bottom: 2px;
}

.marketSchedule {
    margin-top: 8px;
}

.marketSchedule Typography {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 16px;
    color: #333;
}

.marketSchedule p {
    margin: 0;
    font-size: 0.95rem;
    color: #555;
}

.viewDetailsButton {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.viewDetailsButton:hover {
    background-color: #0056b3;
}