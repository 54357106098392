/* ImageCarousel.css */
.shimmer {
    position: relative;
    height: 250px; /* Altura del carrusel */
    overflow: hidden;
    background: #e0e0e0; /* Color de fondo del placeholder */
}

.shimmer:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}
