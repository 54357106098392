.hospedajes-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Dos columnas */
    gap: 16px; /* Espacio entre las tarjetas */
    padding: 16px; /* Espacio alrededor del contenedor */
  }
  
  .card {
    background-color: white;
    border-radius: 8px; /* Esquinas redondeadas */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Sombra para la elevación */
    text-align: center; /* Centrar contenido */
    transition: box-shadow 0.3s; /* Transición suave */
  }
  
  .card:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Aumentar sombra al pasar el mouse */
  }
  
  .card-logo {
    width: 100%; /* Asegúrate de que la imagen sea responsiva */
    height: 150px; /* Establece una altura fija para todas las imágenes */
    object-fit: cover; /* Asegúrate de que la imagen cubra el área sin distorsionarse */
    border-radius: 8px 8px 0 0; /* Esquinas redondeadas en la parte superior */
    padding: 0; /* Sin padding en las imágenes */
  }
  
  .card-name {
    margin: 8px 0; /* Espacio entre la imagen y el nombre */
    overflow: hidden; /* Ocultar el texto que se desborda */
    text-overflow: ellipsis; /* Agregar puntos suspensivos al final */
    display: -webkit-box; /* Utilizar flexbox para limitar las líneas */
    -webkit-box-orient: vertical; /* Orientación vertical para el box */
    -webkit-line-clamp: 2; /* Limitar a 2 líneas */
    text-align: start;
    padding-left: 6px;
    padding-right: 6px;
    line-clamp: 2; /* Asegúrate de que funcione en navegadores compatibles */
    font-size: 16px; /* Ajusta el tamaño de la fuente según tus necesidades */
  }
  
  .card-name {
    font-weight: bold; /* Si deseas que el nombre sea negrita */
  }

  .no-events-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* O el contenedor deseado */
    text-align: center;
  }
  
  .no-events-container h3 {
    color: #555;
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  